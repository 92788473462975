import _process from "process";
var exports = {};
var process = _process;

const pathKey = (options = {}) => {
  const environment = options.env || process.env;
  const platform = options.platform || process.platform;

  if (platform !== "win32") {
    return "PATH";
  }

  return Object.keys(environment).reverse().find(key => key.toUpperCase() === "PATH") || "Path";
};

exports = pathKey; // TODO: Remove this for the next major release

exports.default = pathKey;
export default exports;